import axios from '@axios'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          // .get(`${this.AxiosUrl}api_mes/persen-resep-ctr/view`, { params: queryParams })
          .get(`${useAppConfig().AxiosUrl}api_mes/persen-resep-ctr/view`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // fetchDataEdit(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post(`${useAppConfig().AxiosUrl}api_mes/persen-resep-ctr/editview`, { id })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    crudData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${useAppConfig().AxiosUrl}api_mes/persen-resep-ctr/crud`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
