<template>

  <div>

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :user-data="userData"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <list-filters
      :role-filter.sync="roleFilter"
      :role-options="roleOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="AddData()"
              >
                <span class="text-nowrap">Add Persentase Resep</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        :busy.sync="isBusy"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        striped
      >

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-button
              variant="outline-primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </template>

        <!-- Column: Actions -->
        <!-- <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="EditData(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon
                icon="TrashIcon"
              />
              <span
                class="align-middle ml-50"
                @click="showMsgBoxTwo(data.item.id_resep, data.item.group_item)"
              >Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template> -->

        <template #cell(actions)="data">
          <div
            class="text-nowrap"
          >
            <div style="line-height:80%;">
              <br>
            </div>
            <feather-icon
              :id="`invoice-row-${data.item.id_resep}-send-icon-${data.item.group_item}-`"
              icon="EditIcon"
              class="cursor-pointer"
              :class="`text-primary`"
              size="17"
              @click="EditData(data.item)"
            />
            <b-tooltip
              :title="`Edit ${data.item.description}`"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id_resep}-send-icon-${data.item.group_item}-`"
            />
&nbsp;
            <feather-icon
              :id="`invoice-row2-${data.item.id_resep}-send-icon-${data.item.group_item}-`"
              icon="Trash2Icon"
              class="cursor-pointer"
              :class="`text-danger`"
              size="17"
              @click="showMsgBoxTwo(data.item.id_resep, data.item.group_item)"
            />
            <b-tooltip
              :title="`Delete ${data.item.description}`"
              class="cursor-pointer"
              :target="`invoice-row2-${data.item.id_resep}-send-icon-${data.item.group_item}-`"
            />
          </div>
          <div style="line-height:80%;">
            <br>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BTooltip, BSpinner, // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import ListFilters from './ListFilters.vue'
// eslint-disable-next-line import/no-cycle
import useList from './useList'
import StoreModule from '../StoreModule'
import AddNew from './AddNew.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ListFilters,
    AddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const { AxiosUrl } = useAppConfig()

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, StoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankTask = {
      id_resep: '',
      group_item: '',
      persen: '',
      action: 'Add',
    }

    const isAddNewUserSidebarActive = ref(false)
    const userData = ref(JSON.parse(JSON.stringify(blankTask)))

    const roleOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${AxiosUrl}api_mes/resep-ctr/view`)
      const dataAPI = result.data.data
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        combo.push({ label: dataAPI[i].nama, value: dataAPI[i].id_resep })
      }
      roleOptions.value = combo
    })

    const EditData = taskData => {
      userData.value = taskData
      isAddNewUserSidebarActive.value = true
    }

    const AddData = () => {
      userData.value = blankTask
      isAddNewUserSidebarActive.value = true
    }

    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
    } = useList()

    return {
      userData,
      AddData,
      EditData,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,

      // Extra Filters
      roleFilter,
    }
  },
  methods: {
    async showMsgBoxTwo(id, groupitem) {
      console.log(id)
      this.boxTwo = id
      this.$bvModal
        .msgBoxConfirm('Are You Sure To Delete This Data?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          console.log(value)
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete')
            params.append('id_resep', id)
            params.append('group_item', groupitem)
            const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/persen-resep-ctr/delete`, params)
            console.log(result)
            if (result.data.message === 'Data Deleted') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Error Deleting Data',
                  variant: 'danger',
                },
              })
              // console.log(result)
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
